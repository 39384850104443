import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTable, useSortBy } from 'react-table';
import { Container } from 'react-bootstrap';
import shortid from 'shortid';

import { toast } from 'react-toastify';
import apiv2 from '../utils/apiv2';
import { sortByDateInMilliseconds } from '../utils/helper';
import Head from '../components/Head';
import Button from '../components/Core/Button';

import PaymentType, {
  payOnDelievery,
  paymentTypes,
  paymentInitialValues,
} from '../components/Payment/PaymentType';
import { availablePaymentMethods, labelRoles } from '../utils/constants';

import { useTranslation } from 'react-i18next';
import Loader from '../components/Core/Loader';
import { convertMsToHM } from '../utils/time';
import { loadStripe } from '@stripe/stripe-js/pure';
loadStripe.setLoadParameters({ advancedFraudSignals: false });
// eslint-disable-next-line import/first
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

export const CheckoutForm = () => {
  const { t, i18n } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const userData = user.data;
  const { employer } = userData;

  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [stripeCustomerId, setStripeCustomerId] = useState(
    userData?.stripe_account_id,
  );

  useEffect(() => {
    if (
      !location.state ||
      !location.state.jobs ||
      !location.state.jobs.length
    ) {
      if (userData.role === 'superuser') {
        history.push(`/${labelRoles.superuser}/eventslist`);
      } else {
        history.push(`/${labelRoles.employer}/request-overview`);
      }
    } else if (location.state.jobs && location.state.jobs.length) {
      let localTotalCost = 0;
      location.state.jobs.map((job, id) => {
        localTotalCost += job?.employerData?.confirmJobTotalCost
          ? job?.employerData?.confirmJobTotalCost
          : job?.employerData?.jobTotalCost
          ? job?.employerData?.jobTotalCost
          : 0;
      });
      setTotalCost(Number(localTotalCost).toFixed(2));
    }
  }, [location]);

  useEffect(() => {
    if (message) {
      toast.success(message);
    }
  }, [message]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!user.data.employer.billingAddress) {
      toast.error(t('billingaddressdetails'));
      setIsLoading(false);
      return;
    }

    let clientSecretdata;
    if (totalCost > 0) {
      const jobpositionids = location.state.jobs.map((job) => ({
        id: job._id,
        eventId: job.eventId || job.eventID,
      }));

      const employerData = {
        companyName: employer.companyName || '',
        country: employer.country || '',
        postalCode: employer.postalCode || '',
        city: employer.city || '',
        addressLineOne: employer.addressLineOne || '',
        VAT: employer.companyVatNumber || '',
        billingAddress: {
          billingAddressName: employer?.billingAddress?.billingAddressName,
          billingAddressCountry:
            employer?.billingAddress?.billingAddressCountry,
          billingAddressCity: employer?.billingAddress?.billingAddressCity,
          billingAddressPostCode:
            employer?.billingAddress?.billingAddressPostCode,
          billingAddressStreet: employer?.billingAddress?.billingAddressStreet,
          billingAddressStreetTwo:
            employer?.billingAddress?.billingAddressStreetTwo,
        },
      };

      const requestData = {
        employer: employerData,
        jobids: jobpositionids,
        paymentMethod: availablePaymentMethods.creditCard,
      };

      clientSecretdata = await apiv2.post('/order', requestData);
      clientSecretdata = clientSecretdata?.data;
      const stripeCustomerId = clientSecretdata?.data?.customerId;

      if (!stripeCustomerId) {
        return;
      }
      setStripeCustomerId(stripeCustomerId);

      if (!user.data.employer.billingAddress) {
        toast.error(t('billingaddressdetails'));
        setIsLoading(false);
        return;
      }

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      if (!elements.getElement(CardElement)) {
        return;
      } else {
        let { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });

        if (error) {
          toast.error(error.message);
          setIsLoading(false);
          return;
        } else {
          const paymentSummaryPath =
            userData.role === 'superuser'
              ? `/${labelRoles.superuser}/request-book-payment-summary`
              : `/${labelRoles.employer}/request-book-payment-summary`;

          history.push({
            pathname: paymentSummaryPath,
            state: {
              jobs: location?.state?.jobs || [],
              employee: location?.state?.employee || {},
              invoice: clientSecretdata?.data?.invoice,
              paymentMehods: String(paymentMethod.id),
            },
          });
        }
      }
      setIsLoading(true);

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      setIsLoading(false);
    }
  };

  const inputStyle = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: 'black',
        fontWeight: '500',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: 'black',
        },
      },
      invalid: {
        iconColor: '#EE5F38',
        color: '#EE5F38',
      },
    },
    value: { postalCode: employer?.billingAddress?.billingAddressPostCode },
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement options={inputStyle} className="card-element" />

      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="btn primary-btn m-3">
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">
              {t('completeorder')}
            </div>
          ) : (
            `${t('completeorder')}`
          )}
        </span>
      </button>
      {/*Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

const Table = ({ columns, data }) => {
  const { t, i18n } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table
        className="table remove-space request-payment-table"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={shortid.generate()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()} key={shortid.generate()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={shortid.generate()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.id === 'costs') {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={shortid.generate()}
                          className="no-wrap-cls">
                          <span className="total-amount">
                            {cell.render('Cell')}
                          </span>
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()} key={shortid.generate()}>
                          <span>{cell.render('Cell')}</span>
                        </td>
                      );
                    }
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TableAddress = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );

  return (
    <div className="table-responsive">
      <table className="table show-address-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={shortid.generate()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()} key={shortid.generate()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={shortid.generate()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={shortid.generate()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const RequestPayment = () => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const { t, i18n } = useTranslation();
  const [totalCost, setTotalCost] = useState(0);

  const user = useSelector((state) => state.user);
  const userData = user.data;
  const { employer } = userData;
  const paymentDetails = employer?.paymentDetails;
  const paymentMethods = employer?.paymentMethods;

  const [stripeCustomerId, setStripeCustomerId] = useState(
    userData?.stripe_account_id,
  );
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const [paymentType, setPaymentType] = useState(
    paymentDetails?.paymentMethod &&
      paymentMethods.includes(paymentDetails?.paymentMethod)
      ? paymentDetails?.paymentMethod
      : paymentMethods && paymentMethods.length > 1
      ? paymentTypes[0].value
      : paymentMethods[0],
  );

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !location.state ||
      !location.state.jobs ||
      !location.state.jobs.length
    ) {
      if (userData.role === 'superuser') {
        history.push(`/${labelRoles.superuser}/eventslist`);
      } else {
        history.push(`/${labelRoles.employer}/request-overview`);
      }
    } else if (location.state.jobs && location.state.jobs.length) {
      let localTotalCost = 0;
      location.state.jobs.map((job, id) => {
        localTotalCost += job?.employerData?.confirmJobTotalCost
          ? job?.employerData?.confirmJobTotalCost
          : job?.employerData?.jobTotalCost
          ? job?.employerData?.jobTotalCost
          : 0;
      });
      setTotalCost(Number(localTotalCost).toFixed(2));
    }
  }, [location]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'id',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventId')}`;
        },
        accessor: 'eventId',
      },
      {
        Header: () => {
          return `${t('requestOverview.eventName')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('requestPayment.jobType')}`;
        },
        accessor: 'jobType',
      },
      {
        Header: () => {
          return `${t('requestPayment.jobDate')}`;
        },
        accessor: 'jobDate',
      },
      {
        Header: () => {
          return `${t('event.hours')}`;
        },
        accessor: 'hours',
      },
      {
        Header: () => {
          return `${t('requestPayment.jobCity')}`;
        },
        accessor: 'jobCity',
      },

      {
        Header: () => {
          return `${t('Costs')}`;
        },
        accessor: 'costs',
      },
    ];
  }, []);

  const addcolumns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('profilebilldetailname')}`;
        },
        accessor: 'name',
      },
      {
        Header: () => {
          return `${t('profileOrg.billAddress')}`;
        },
        accessor: 'street',
      },
      {
        Header: () => {
          return `${t('postalCodeStaff')}`;
        },
        accessor: 'postCode',
      },
      {
        Header: () => {
          return `${t('city')}`;
        },
        accessor: 'city',
      },
      {
        Header: () => {
          return `${t('country')}`;
        },
        accessor: 'country',
      },
    ];
  }, []);

  const addressData = React.useMemo(() => {
    const billingAddress = user.data.employer.billingAddress;
    if (billingAddress) {
      return [
        {
          name: billingAddress.billingAddressName,
          street: `${billingAddress.billingAddressStreet} ${
            billingAddress.billingAddressStreetTwo
              ? '/ ' + billingAddress.billingAddressStreetTwo
              : ''
          }`,
          postCode: billingAddress.billingAddressPostCode,
          city: billingAddress.billingAddressCity,
          country: billingAddress.billingAddressCountry,
        },
      ];
    } else {
      return [];
    }
  }, []);

  const renderTable = (jobs) => {
    sortByDateInMilliseconds(jobs);
    const data = jobs?.map((job, id) => {
      return {
        id: `${id + 1}`,
        eventId: job?.eventId,
        eventName: job?.eventName,
        jobType: job?.jobName,
        jobDate: moment(job?.jobDate).tz(process.env.REACT_APP_TIMEZONE).utc().format('DD.MM.YYYY'),
        // hours: `${getTimeDifferent(
        //   job?.confirmJobStart ? job?.confirmJobStart : job?.jobStart,
        //   job?.confirmJobEnd ? job?.confirmJobEnd : job?.jobEnd,
        // )} h`,
        hours: `${convertMsToHM(
          (job?.confirmJobEnd ? job?.confirmJobEnd : job?.jobEnd) -
            (job?.confirmJobStart ? job?.confirmJobStart : job?.jobStart),
        )}`,
        jobCity: job?.jobPlaceAddress?.city,
        name: job?.staffToBook?.applicant?.name,
        costs: `€ ${Number(
          job?.employerData?.jobTotalCost ? job?.employerData?.jobTotalCost : 0,
        ).toFixed(2)}`,
      };
    });
    return <Table columns={columns} data={data} />;
  };

  const formik = useFormik({
    initialValues: {
      ...paymentInitialValues,
      paymentMethod:
        paymentDetails && paymentDetails.paymentMethod
          ? paymentDetails.paymentMethod
          : '',
    },
    onSubmit: async (values) => {
      if (!user.data.employer.billingAddress) {
        toast.error(t('billingaddressdetails'));
        return;
      }

      setLoading(false);

      if (userData.role === 'superuser') {
        history.push({
          pathname: `/${labelRoles.superuser}/request-book-payment-summary`,
          state: {
            jobs: location && location.state ? location.state.jobs : [],
          },
        });
      } else {
        history.push({
          pathname: `/${labelRoles.employer}/request-book-payment-summary`,
          state: {
            jobs: location && location.state ? location.state.jobs : [],
          },
        });
      }
      // }
    },
  });
  useEffect(() => {
    formik.setFieldValue('paymentMethod', paymentType);
  }, [paymentType]);
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    // clientSecret,
    appearance,
  };

  return (
    <section className="content-section">
      <Head title={t('title.requestBookPay')} />
      <Container>
        <div className="box-wrapper">
          {loading && <Loader />}
          <div
            className="form-back"
            onClick={() => {
              if (userData.role === 'superuser') {
                history.push(`/${labelRoles.superuser}/eventslist`);
              } else {
                history.push(`/${labelRoles.employer}/request-overview`);
              }
            }}></div>
          <div className="book-pay-details">
            <h3>{t('BookandPay')}</h3>
            {/* <Table columns={columns} data={data} /> */}
            {location && location.state && renderTable(location.state.jobs)}
            {/*<div className="d-flex justify-content-end align-items-center mb-4">
              <span className="discount-text">{t('PromotionalDiscount')} </span>
              <span className="total-amount">€ {discount}*</span>
            </div>*/}
            <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="placeholder-text">
                {/*{t('jobWallStaff.textOne')}*/}
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <span className="placeholder-text">{t('event.totalExcl')}</span>
                <span className="total-amount">€ {totalCost}</span>
              </div>
            </div>
          </div>
          <div className="billing-details">
            <h3 className="headline-with-edit">
              <span>{t('profileOrg.billAddress')}</span>
              <span
                className="edit-icon"
                onClick={() => {
                  if (userData.role === 'superuser') {
                    history.push(
                      `/${labelRoles.superuser}/profile?tab=payment-method`,
                    );
                  } else {
                    history.push(
                      `/${labelRoles.employer}/profile?tab=payment-method`,
                    );
                  }
                }}></span>
            </h3>
            <TableAddress columns={addcolumns} data={addressData} />
          </div>
          <div className="payment-detail-wrapper">
            <h3>{t('profileOrg.payment')}</h3>
            <PaymentType
              formik={formik}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              paymentMethods={paymentMethods}
            />
            {paymentType !== payOnDelievery &&
            paymentMethods?.find((i) => i !== payOnDelievery) ? (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            ) : (
              <Button
                className="btn primary-btn"
                type="button"
                onClick={async (e) => {
                  e.preventDefault();
                  if (!user.data.employer.billingAddress) {
                    toast.error('Please provide billing address details!');
                    return;
                  }

                  const jobpositionids = location.state.jobs
                    .filter(
                      (job) =>
                        !job.isConfirmed ||
                        (!job.paidOutstandingAmount &&
                          !isNaN(job.outstandingAmount) &&
                          job.outstandingAmount > 0),
                    )
                    .map((job) => ({
                      id: job._id,
                      eventId: job.eventId || job.eventID,
                    }));

                  const employerData = {
                    companyName: employer.companyName || '',
                    country: employer.country || '',
                    postalCode: employer.postalCode || '',
                    city: employer.city || '',
                    addressLineOne: employer.addressLineOne || '',
                    VAT: employer.companyVatNumber || '',
                    billingAddress: {
                      billingAddressName:
                        employer?.billingAddress?.billingAddressName,
                      billingAddressCountry:
                        employer?.billingAddress?.billingAddressCountry,
                      billingAddressCity:
                        employer?.billingAddress?.billingAddressCity,
                      billingAddressPostCode:
                        employer?.billingAddress?.billingAddressPostCode,
                      billingAddressStreet:
                        employer?.billingAddress?.billingAddressStreet,
                      billingAddressStreetTwo:
                        employer?.billingAddress?.billingAddressStreetTwo,
                    },
                  };

                  const requestData = {
                    employer: employerData,
                    employeeId: location?.state?.employee?._id,
                    jobids: jobpositionids,
                    paymentMethod: availablePaymentMethods.invoice,
                  };

                  try {
                    const clientSecretdata = await apiv2.post(
                      '/order',
                      requestData,
                    );
                    if (!clientSecretdata) {
                      toast.error(
                        'An error occured while creating payment intent',
                      );
                      return;
                    }

                    if (userData.role === 'superuser') {
                      history.push({
                        pathname: `/${labelRoles.superuser}/request-book-payment-summary`,
                        state: {
                          jobs:
                            location && location.state
                              ? location.state.jobs
                              : [],
                          customerId: stripeCustomerId ? stripeCustomerId : '',
                          isInvoice: true,
                          invoice: clientSecretdata?.data?.data?.invoice,
                        },
                      });
                    } else {
                      history.push({
                        pathname: `/${labelRoles.employer}/request-book-payment-summary`,
                        state: {
                          jobs:
                            location && location.state
                              ? location.state.jobs
                              : [],
                          customerId: stripeCustomerId ? stripeCustomerId : '',
                          isInvoice: true,
                          invoice: clientSecretdata?.data?.data?.invoice,
                        },
                      });
                    }
                  } catch (error) {
                    toast.error(error);
                  }
                }}
                label={t('completeorder')}
              />
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RequestPayment;
