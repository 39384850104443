import React from 'react';
// import { Row, Col } from 'react-bootstrap';
// import { IMaskInput } from 'react-imask';
// import IMask from 'imask';
// import DatePicker from 'react-datepicker';
// import moment from 'moment';
import * as Yup from 'yup';
import shortid from 'shortid';
// import Input from '../Core/Input';
import visaIcon from '../../assets/images/icons/payment-visa.svg';
import masterCard from '../../assets/images/icons/payment-mastercard.svg';
import invoiceIcon from '../../assets/images/icons/payment-invoice.svg';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useBootstrapBreakpoints } from 'react-bootstrap/esm/ThemeProvider';
import { t } from 'i18next';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
// import CheckoutForm from './CheckoutForm';

export const paymentInitialValues = {
  paymentMethod: '',
  cardName: '',
  cardNumber: '',
  validThrough: '',
  cvv: '',
  chkSavePaymentDetails: '',
};

export const paymentValidationSchema = Yup.object({
  // paymentMethod: Yup.object().shape({
  //   label: Yup.string().required('Payment Method is required'),
  //   value: Yup.string().required('Payment Method is required'),
  // }),
  cardName: Yup.string().required(t('name')),
  cardNumber: Yup.string()
    .min(16, t('card.length'))
    .required(t('card.required')),
  validThrough: Yup.string().required(t('card.validThrough')),
  cvv: Yup.string()
    .length(3, t('card.cvvLength'))
    .matches(/[0-9]+/gi, 'CVV must be number')
    .required(t('card.cvvRequired')),
});
// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

export const payOnDelievery = 'onInvoice';

export const paymentTypes = [
  {
    label: 'VISA',
    value: 'visa',
    image: visaIcon,
  },
  {
    label: 'Master Card',
    value: 'masterCard',
    image: masterCard,
  },
  {
    label: 'On Invoice',
    value: payOnDelievery,
    image: invoiceIcon,
  },
];

const PaymentType = ({
  // formik,
  paymentType,
  setPaymentType,
  paymentMethods,
  clientSecret,
}) => {
  // const userData = useSelector((state) => state.user);
  // const user = userData.data;
  // const { employer } = user;
  const { t } = useTranslation();
  // const { paymentDetails } = employer;
  // const [expirationDateError, setExpirationDateError] = useState(null);
  // const { values, errors, touched, handleChange } = formik;
  // const handleAccept = (value, setFieldValue) => {
  //   if (value.length >= 5) {
  //     const newDate = moment(`01/${value}`, 'DD/MM/YYYY').format('x') * 1;
  //     const currTime = new Date().valueOf();
  //     if (newDate > currTime) {
  //       setExpirationDateError(null);
  //     } else {
  //       setExpirationDateError(t('cardexpired'));
  //     }
  //   } else {
  //     setExpirationDateError(null);
  //   }
  //   setFieldValue('validThrough', value);
  // };
  // const appearance = {
  //   theme: 'stripe',
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };

  return (
    <div className="payment-methods">
      <p className="lead">{t('choosepaymentmethod')}</p>

      <div className="payment-method-options">
        {paymentTypes.map((type) => {
          if (paymentMethods?.find((i) => i === type.value)) {
            return (
              <div
                key={type.value}
                className={`payment-method-option ${
                  paymentType === type.value ? 'active' : ''
                }`}>
                <div
                  onClick={() => setPaymentType(type.value)}
                  key={shortid.generate()}>
                  <img src={type.image} alt={type.label} />
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default PaymentType;
