import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas } from 'react-bootstrap';
import Loader from '../../components/Core/Loader';
import GlobalContext from '../../context/GlobalContext';
import {
  updateJobPosition,
  checkEmployeestatus,
} from '../../actions/jobAction';
import { constructGCSImageUrl } from '../../utils/helper';
import { labelRoles } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import defaultAvatar from '../../assets/images/default-avatar.png';

const BookingList = (props) => {
  const dispatch = useDispatch();
  const [listLoading, setListLoading] = useState(false);
  const eventsData = useSelector((state) => state.events);
  const [deleteJob, setDeleteJob] = useState(false);
  const user = useSelector((state) => state.user);
  const userData = user.data;
  const history = useHistory();
  const { t } = useTranslation();
  const [cartJobs, setCartJobs] = useState([]);
  const { listLoadingExtern, showBookingList, setShowBookingList } =
    useContext(GlobalContext);

  useEffect(() => {
    let localCartJobs = [];
    setListLoading(true);
    const events = eventsData.allEvents.events;
    if (events && events.length) {
      events.map((event) => {
        event.jobs.map((job) => {
          job.jobPositions.map((position) => {
            if (
              !position.booked &&
              position.staffToBook &&
              position.staffToBook.applicant
            ) {
              localCartJobs = [
                ...localCartJobs,
                {
                  ...position,
                  jobName: job.jobName,
                  eventName: job.eventName,
                  eventID: job.eventID,
                  eventId: event.eventID,
                  companyName: job.companyName,
                },
              ];
            }
          });
        });
      });
    }
    setListLoading(false);
    localCartJobs.sort((a, b) => new Date(a.jobStart) - new Date(b.jobStart));
    setCartJobs([...localCartJobs]);
  }, [eventsData]);

  useEffect(() => {
    if (cartJobs && cartJobs.length) {
      updatecart();
    }
    async function updatecart() {
      const newCartJobs = await Promise.all(
        cartJobs.map(async (job) => {
          if (
            job?.staffToBook &&
            job?.staffToBook?.applicant &&
            job?.staffToBook?.applicant?.images &&
            job?.staffToBook?.applicant?.images.length
          ) {
            const imageResult =
              constructGCSImageUrl(job.staffToBook.applicant.images[0]) ||
              defaultAvatar;
            job.staffToBook.applicant.images[0] = imageResult;
          }
          return job;
        }),
      );
      setCartJobs([...newCartJobs]);
    }

    setListLoading(false);
  }, [cartJobs.length, deleteJob]);

  const renderCartJobs = () => {
    return cartJobs.map((job, index) => {
      const currentlanguage1 = localStorage.getItem('lang');
      const currentlanguage2 = localStorage.getItem('i18nextLng');

      let dateLangFormat = '';
      if (
        (currentlanguage1 && currentlanguage1 !== 'de') ||
        (currentlanguage2 && currentlanguage2 !== 'de')
      ) {
        dateLangFormat = 'en-US';
      } else {
        dateLangFormat = 'de-DE';
      }
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      const optionsHhMm = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
      const date = new Date(job.jobStart);
      const formattedDate = date.toLocaleDateString(dateLangFormat, options);
      const startTime = new Date(job.jobStart); // Convert the timestamp to milliseconds
      const formattedStartTime = startTime.toLocaleTimeString(
        'de-DE',
        optionsHhMm,
      );
      const endTime = new Date(job.jobEnd); // Convert the timestamp to milliseconds
      const formattedEndTime = endTime.toLocaleTimeString('de-DE', optionsHhMm);
      const { applicant } = job.staffToBook;
      const { blockedJobs } = applicant;
      const flag = blockedJobs.some(
        (blockedJob) => blockedJob.jobPositionID === job._id,
      );
      return (
        <div className="d-flex justify-content-between" key={index}>
          <div className="profile-book">
            {userData.role === 'superuser' ? (
              <>
                <div style={{ float: 'left' }}>
                  <Link
                    to={`/staff-detail/${applicant._id}`}
                    target="_blank"
                    className="profile-wrap">
                    <span className="profile-image active">
                      <img
                        src={
                          constructGCSImageUrl(applicant.images[0]) ||
                          defaultAvatar
                        }
                        alt={t('requestOverview.staff')}
                      />
                    </span>
                    {flag && <span className="book-time">45m</span>}
                  </Link>
                </div>
                <div>
                  <span className="profile-text">{job.jobName}</span>
                  <br />
                  <span className="profile-text" style={{ display: 'flex' }}>
                    {job.eventName}
                  </span>
                  <span className="profile-text" style={{ display: 'flex' }}>
                    {formattedDate}
                  </span>
                  <span className="profile-text" style={{ display: 'flex' }}>
                    {formattedStartTime} - {formattedEndTime}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div style={{ float: 'left' }}>
                  <Link
                    to={`/staff-details/${applicant._id}`}
                    target="_blank"
                    className="profile-wrap">
                    <span className="profile-image active">
                      <img
                        src={
                          constructGCSImageUrl(applicant.images[0]) ||
                          defaultAvatar
                        }
                        alt={t('requestOverview.staff')}
                      />
                    </span>
                    {flag && <span className="book-time">45m</span>}
                  </Link>
                </div>
                <div>
                  <span className="profile-text">{job.jobName}</span>
                  <br />
                  <span className="profile-text" style={{ display: 'flex' }}>
                    {job.eventName}
                  </span>
                  <span className="profile-text" style={{ display: 'flex' }}>
                    {formattedDate}
                  </span>
                  <span className="profile-text" style={{ display: 'flex' }}>
                    {formattedStartTime} - {formattedEndTime}
                  </span>
                </div>
              </>
            )}
          </div>
          <div
            className="field-action-icon"
            onClick={() => {
              setDeleteJob(true);
              const newJob = {
                ...job,
                staffToBook: {},
                readyToBook: {
                  addedAt: job?.readyToBook?.addedAt,
                  applicant: {
                    // images: applicant?.images,
                    // name: applicant?.name,
                    _id: applicant?._id,
                  },
                },
              };
              delete newJob.applications;
              delete newJob._id;
              dispatch(
                updateJobPosition({
                  id: job._id,
                  payload: newJob,
                  callback: () => {
                    setDeleteJob(false);

                    toast.success(t('Staffremoved'));
                    if (props?.fetchStaffDetails) {
                      props?.fetchStaffDetails();
                    }
                  },
                  failCallback: () => {
                    setDeleteJob(false);
                  },
                  staff: applicant?._id,
                }),
              );
            }}>
            <svg width="19" height="22" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                fill="#41A9C7"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      );
    });
  };

  const checkstatus = async (cartJobs) => {
    setListLoading(true);
    await cartJobs.map((job) => {
      dispatch(
        checkEmployeestatus({
          jobId: job._id,
          employee: job.staffToBook.applicant,
          callback: () => {},
          failCallback: () => {
            setListLoading(false);
            history.push(`/${labelRoles.employer}/request-overview`);
          },
        }),
      );
    });
  };

  return (
    <Offcanvas
      show={showBookingList}
      onHide={() => setShowBookingList(false)}
      backdrop={false}
      scroll={true}
      {...props?.OffcanvasProps}>
      {(listLoading || deleteJob) && <Loader />}{' '}
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t('Booking_list')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="booking-list-wrapper">
          {!listLoadingExtern && cartJobs && cartJobs.length ? (
            <>
              {userData.role === 'superuser' ? (
                <Link
                  className="btn mb-4"
                  onClick={() => {
                    setListLoading(true);
                    checkstatus(cartJobs);
                  }}
                  to={{
                    pathname: `/${labelRoles.superuser}/request-book-and-pay`,
                    state: {
                      jobs: cartJobs,
                      employee: cartJobs.map(
                        (job) => job.staffToBook.applicant,
                      ),
                    },
                  }}>
                  {t('profile.btnBookNow')}
                </Link>
              ) : (
                <Link
                  className="btn mb-4"
                  onClick={() => {
                    setListLoading(true);
                    checkstatus(cartJobs);
                  }}
                  to={{
                    pathname: `/${labelRoles.employer}/request-book-and-pay`,
                    state: {
                      jobs: cartJobs,
                      employee: cartJobs.map(
                        (job) => job.staffToBook.applicant,
                      ),
                    },
                  }}>
                  {t('profile.btnBookNow')}
                </Link>
              )}
              {<div className="staff-booking-list">{renderCartJobs()}</div>}
            </>
          ) : (
            <div>{t.nostaff}</div>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default BookingList;
