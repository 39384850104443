import React from 'react';
import apiv2 from '../../utils/apiv2';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PaymentDetails = () => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const stripeAccountID = user.data.stripe_account_id;

  const [paymentData, setPaymentData] = React.useState(null);

  React.useEffect(() => {
    apiv2
      .post('/user/get-payment-method', {
        stripe_account_id: stripeAccountID,
      })
      .then(({ data }) => setPaymentData(data.data.user.payment))
      .catch((error) => console.error('Error fetching payment data:', error));
  }, [stripeAccountID]);

  return paymentData ? (
    <div className="payment-method">
      <h3>{t('profileOrg.payment')}</h3>
      <div className="payment-method__details">
        <p>
          <strong>{t('creditCard')}</strong> {paymentData.brand.toUpperCase()}
          <br />
          <strong>{t('cardNumber')}</strong> **** **** **** {paymentData.last4}
          <br />
          <strong>{t('validThrough')}</strong>{' '}
          {`${paymentData.exp_month < 10 ? '0' : ''}${paymentData.exp_month}/${
            paymentData.exp_year % 100
          }`}
        </p>
      </div>
    </div>
  ) : (
    <p className="payment-method__no-data">{t('noPaymentDetails')}</p>
  );
};

export default PaymentDetails;
