import React, {useEffect, useContext, useState} from 'react';
import GlobalContext from '../context/GlobalContext';
import Head from '../components/Head';
import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import Button from '../components/Core/Button';
import prmotionjobs from '../assets/images/prmotionjobs.jpg';
import posjobs from '../assets/images/posjobs.jpg';
import gastrojobs from '../assets/images/gastrojobs.jpg';
import hospitalityjobs from '../assets/images/hospitalityjobs.jpg';
import videoback from '../assets/images/video-screen.png';
import { useTranslation } from 'react-i18next';
import video from '../assets/images/workwithus_video.mp4';
import {Link, useLocation} from 'react-router-dom';
import teaserButton from '../assets/images/job-teaser_ticket-btn.svg';
import api from "../utils/api";
import {dateToWeekDay, dateToTime} from '../utils/helper';
import {useSelector} from "react-redux";
import {labelRoles} from "../utils/constants";
import Newsletter from '../components/Newsletter/NewsletterForm';
import ExitIntentPopup from '../components/Newsletter/NewsletterExitPopup';

const WorkWithUs = () => {
  const [filteredJobs, setFilteredJobs] = useState([]);
  const { showLoginModal, setShowLoginModal } = useContext(GlobalContext);
  const { t, i18n } = useTranslation();
  const { setShowEmployeeRegisterModal, showEmployeeRegisterModal } =
    useContext(GlobalContext);
  const location = useLocation();
  const [disableNewsletterPopUp, setDisableNewsletterPopUp] = useState(false);
  const userData = useSelector((state) => state.user);
  const hasSubscribed = localStorage.getItem('hasSubscribed');
  const hasClosedPopup = localStorage.getItem('hasClosedPopup');
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  const renderTeaserJobs = () => {
    return filteredJobs.map((job) => {
      return (
        !isRedirecting && (
          <div key={job.id} className="job-teaser__ticket">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div className="job-teaser__ticket-info">
              <h4>{job.jobName}</h4>
              <p className="job-teaser__ticket-info__event">
                <span>{job.eventName}</span>
                <span>{job.jobPlace}</span>
              </p>
              <p>Anzahl: {job.count}</p>
              <p className="job-teaser__ticket-info__date">
                <span>
                  {t('weekday' + dateToWeekDay(job.jobStart))}
                  <br></br>
                  {new Date(job.jobStart).toLocaleString().split(',')[0]}
                </span>
                <span>
                  {t('worktime')}
                  <br></br>
                  {dateToTime(job.jobStart)} - {dateToTime(job.jobEnd)}
                </span>
              </p>
            </div>
            {(() => {
              if (userData.data.active) {
                return (
                  <Link
                    to={
                      labelRoles[userData.data.role] +
                      '/job-wall-details/' +
                      job.posID +
                      '/' +
                      job.eventID
                    }
                    className="job-teaser__ticket-button">
                    <img src={teaserButton} alt="apply now" />
                  </Link>
                );
              }
              return (
                <div
                  onClick={() =>
                    setShowLoginModal({
                      ...showLoginModal,
                      visible: true,
                      referral:
                        'job-wall-details/' + job.posID + '/' + job.eventID,
                      showRegister: true,
                    })
                  }
                  className="job-teaser__ticket-button">
                  <img src={teaserButton} alt="apply now" />
                </div>
              );
            })()}
          </div>
        )
      );
    });
  };

  useEffect(() => {
    async function fetchTeaserJobs() {
      try {
        let response = await api.get('jobs/getTeaserJobs');
        let orderedJobs = response.data.data.filteredJobs;
        orderedJobs.sort((a, b) => a.jobStart - b.jobStart);
        setFilteredJobs(orderedJobs);
      } catch (e) {
        Error(e);
      }
    }

    fetchTeaserJobs().catch((e) => Error(e));
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const posID = urlParams.get('posID');
    const eventID = urlParams.get('eventID');

    if (posID && eventID) {
      // User came from an external page with posID and eventID in the URL
      if (userData.data.active) {
        // User is already logged in, navigate to job details page
        setIsRedirecting(true);
        window.location.href = `${
          labelRoles[userData.data.role]
        }/job-wall-details/${posID}/${eventID}`;
      } else if (!modalOpened) {
        // User is not active and login modal has not been opened yet, open login modal
        setShowLoginModal({
          ...showLoginModal,
          visible: true,
          referral: `job-wall-details/${posID}/${eventID}`,
          showRegister: true,
        });
        setModalOpened(true);
      }
    }
    if (
      !showEmployeeRegisterModal &&
      (hasSubscribed === 'false' || hasSubscribed === null) &&
      (hasClosedPopup === 'false' || hasClosedPopup === null)
    ) {
      setDisableNewsletterPopUp(false);
    } else {
      setDisableNewsletterPopUp(true);
    }

    const playBtn = document.querySelectorAll('.video-poster-image');

    if (playBtn.length) {
      playBtn.forEach((element) => {
        element.addEventListener('click', () => {
          let vi = document.getElementById('videoTag');
          vi.play();
          document.getElementsByClassName('play-button')[0].hidden = true;
          document.getElementsByClassName(
            'video-poster-image',
          )[0].hidden = true;
        });
      });
    }
  }, [
    showEmployeeRegisterModal,
    showLoginModal,
    hasSubscribed,
    hasClosedPopup,
  ]);
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);

  const renderTeaserSection = () => {
    if (Object.keys(filteredJobs).length) {
      return (
        !isRedirecting && (
          <Row className="reverse">
            <Col md={3} className="text-right mb-5 buttonalign">
              <Button
                className="btn"
                variant="primary"
                label={t('registernow')}
                type="button"
                onClick={() => {
                  setShowEmployeeRegisterModal(true);
                  setDisableNewsletterPopUp(true);
                }}
              />
            </Col>
            <Col md={9}>
              <h2>Aktuelle Jobs</h2>
            </Col>
            <Col md={12}>
              <div className="job-teaser__wrapper">{renderTeaserJobs()}</div>
              <div className="job-teaser__more">
                {(() => {
                  if (userData.data.active) {
                    return (
                      <Link to={labelRoles[userData.data.role] + '/job-wall'}>
                        <Button
                          className="btn btn--text"
                          label={t('morejobs')}
                          type="button"
                        />
                      </Link>
                    );
                  }
                  return (
                    <Button
                      className="btn btn--text"
                      label={t('morejobs')}
                      type="button"
                      onClick={() =>
                        setShowLoginModal({
                          ...showLoginModal,
                          visible: true,
                          referral: 'job-wall/',
                        })
                      }
                    />
                  );
                })()}
              </div>
            </Col>
          </Row>
        )
      );
    }
  };

  return (
    !isRedirecting && (
      <section className="workpage-section content-section">
        <Head title={t('title.workWithUs')} />
        <Container>
          <div className="box-wrapper">
            {renderTeaserSection()}
            <Newsletter />
            <ExitIntentPopup disabled={disableNewsletterPopUp} />
            <Col md={9} className="wide-title">
              <h1>Unsere Jobs bieten dir maximale Flexibilität</h1>
            </Col>
            <Row>
              <Col md={12}>
                <div className="video-iframe-wrapper frame">
                  <video
                    width="100%"
                    height="100%"
                    poster={videoback}
                    id="videoTag"
                    preload="none"
                    controls>
                    <source src={video} type="video/mp4" className="my-tag" />
                  </video>
                  <div className="video-poster-image">
                    <div className="play-button">
                      <svg fill="none" viewBox="0 0 424 424">
                        <g filter="url(#filter0_d_1503_50432)" opacity=".6">
                          <circle
                            cx="212.224"
                            cy="206.201"
                            r="194.657"
                            fill="#F4F4F5"
                          />
                        </g>
                        <g filter="url(#filter1_d_1503_50432)">
                          <path
                            fill="#fff"
                            d="M263.897 206.909l-91.312 63.753V143.156l91.312 63.753z"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_1503_50432"
                            width="423.29"
                            height="423.29"
                            x=".58"
                            y=".219"
                            colorInterpolationFilters="sRGB"
                            filterUnits="userSpaceOnUse">
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              result="hardAlpha"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset dy="5.663" />
                            <feGaussianBlur stdDeviation="8.494" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1503_50432"
                            />
                            <feBlend
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1503_50432"
                              result="shape"
                            />
                          </filter>
                          <filter
                            id="filter1_d_1503_50432"
                            width="125.288"
                            height="161.482"
                            x="155.597"
                            y="128.999"
                            colorInterpolationFilters="sRGB"
                            filterUnits="userSpaceOnUse">
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              result="hardAlpha"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset dy="2.831" />
                            <feGaussianBlur stdDeviation="8.494" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1503_50432"
                            />
                            <feBlend
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1503_50432"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <p>
                  <strong>
                    Du bist auf der Suche nach einem Nebenjob oder Studentenjob?
                  </strong>
                </p>
                <p>
                  Du möchtest gutes Geld mit einem Teilzeitjob verdienen? Dann
                  bist Du bei Superstaff richtig! Wir haben eine Menge
                  Nebenjobs, Teilzeitjobs und Minijobs in einem engagiertem hoch
                  motiviertem Team.
                </p>

                <p>
                  <strong>
                    Unsere Studenten- und Nebenjobs bieten Dir maximale
                    Flexibilität.
                  </strong>
                </p>
                <p>
                  Du legst fest: Was, Wann, Wo und Wie viel Du arbeiten
                  möchtest. Tagesjob, Teilzeitjob, geringfügiger Job,
                  Samstagsjob, oder Wochenendjob – bei Superstaff kannst Du Dir
                  Deine Zeit frei einteilen. Unsere Jobangebote gibt es
                  österreichweit.
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <strong>
                    Superstaff ist seit 14 Jahren Spezialist für exzellentes
                    Promotionpersonal und Sales Promoter.
                  </strong>
                </p>
                <p>
                  Wir bieten laufend Nebenjobs und Teilzeit Studentenjobs wie
                  Promotion Jobs, Hospitality Jobs, Point of Sale Jobs, Catering
                  und Servicekräfte auf höchstem Niveau.
                </p>

                <p>
                  <strong>Und wie sieht es mit der Bezahlung aus?</strong>
                </p>
                <p>
                  Bei uns gibt es absolut faire Bezahlung. Und das immer
                  pünktlich und genau! Unsere Nebenjobs, Teilzeit und
                  Studentenjobs werden monatlich abgerechnet und umgehend
                  ausbezahlt.
                </p>
                <p>Komm in unser Team! Wir freuen uns auf Dich!</p>
              </Col>
            </Row>

            <div className="full-width__teaser-wrap">
              <div className="full-width__image-wrapper">
                <img src={prmotionjobs} alt={t('placeholderimages')} />
              </div>
              <div className="full-width__content">
                <Row>
                  <Col md={12}>
                    <h3>{t('Promotion_Jobs')}</h3>
                  </Col>

                  <Col md={6}>
                    <p>
                      Du bis kommunikativ? Gehst mit Vergnügen aktiv auf Leute
                      zu? Begeisterst gerne potentielle Kunden für ein neues
                      Produkt oder eine Dienstleistung? Dann bist du der
                      perfekte Promoter!
                    </p>

                    <p>
                      <strong>
                        Unsere Promotion Jobs machen Spaß und sind
                        abwechlsungsreich.
                      </strong>
                    </p>
                    <p>
                      Produktneuheiten verteilen in Einkaufszentren, Einsätze
                      auf Festivals, Betreuung von Roadshows in ganz Österreich.
                      Für Abwechslung ist gesorgt.
                    </p>

                    <p>
                      <strong>Wir machen Dich fit für Deine Einsätze.</strong>
                    </p>
                    <p>
                      Damit du fit für Deine Einsätze bist, wirst du von
                      Superstaff umfassend gebrieft. Schriftliche Unterlagen,
                      Gesprächsleitfäden und Videos unterstützen dich dabei.
                      Zusätzlich steht Dir Dein Projektleiter immer mit Rat &
                      Tat zur Seite.
                    </p>
                  </Col>

                  <Col md={6} lg={4}>
                    <p className="wir_title">
                      Wir bieten Dir Promotion Jobs wie z.B.:
                    </p>
                    <ul>
                      <li>Promotionstand Betreuung</li>
                      <li>Flyer- und Produktproben Verteilung</li>
                      <li>Verkostungen</li>
                      <li>UNi Promotions</li>
                      <li>Festival Promotions</li>
                      <li>Kostümpromotions</li>
                      <li>uvm.</li>
                    </ul>

                    <p>Wir erwarten von Dir:</p>
                    <ul>
                      <li>Zuverlässigkeit und Pünktlichkeit</li>
                      <li>Gepflegtes Äußeres und exzellente Umgangsformen</li>
                      <li>Perfekte Deutschkenntnisse</li>
                      <li>Hohe Eigenverantwortung und Flexibilität</li>
                      <li>
                        Österreichische Arbeitserlaubnis und einwandfreien
                        Leumund
                      </li>
                      <li>
                        Ein Smartphone und einen Computer mit Internetzugang und
                        Ausdruckmöglichkeit
                      </li>
                    </ul>
                  </Col>

                  <Col md={12}>
                    <Button
                      className="btn"
                      variant="primary"
                      label={t('home.regLabel')}
                      type="button"
                      onClick={() => {
                        setShowEmployeeRegisterModal(true);
                        setDisableNewsletterPopUp(true);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="full-width__teaser-wrap">
              <div className="full-width__image-wrapper">
                <img src={posjobs} alt="placeholder-images" />
              </div>
              <div className="full-width__content">
                <Row>
                  <Col md={12}>
                    <h3>{t('Point_of_Sale_Jobs')}</h3>
                  </Col>

                  <Col md={6}>
                    <p>
                      Du bis kommunikativ und kontaktfreudig? Liebst es Kunden
                      zu beraten? Du bist technisch kompetent und kannst
                      komplexe Dinge gut erklären? Dann bist Du der perfekte POS
                      Promoter. Am Point of Sale (POS) berätst Du Kunden zu
                      neuen Produkten und kurbelst aktiv den Verkauf an.
                    </p>

                    <p>
                      <strong>
                        Wir bereiten Dich auf Deine POS Aufgabe professionell
                        vor.
                      </strong>
                    </p>
                    <p>
                      Damit Du DER Experte am POS wirst, bekommst Du von uns
                      professionelle Unterstützung. Umfassende Produktschulungen
                      statten dich mit Spezialwissen aus. Zusätzliche
                      Verkaufsschulungen geben Dir Tipps für ein professionelles
                      und erfolgreiches Verkaufsgespräch. Live, Online oder
                      mittels Video Tutorials. Zusätzlich steht Dir Dein
                      Projektleiter immer mit Rat & Tat zur Seite
                    </p>

                    <p>
                      <strong>POS Jobs, mehr als verkaufen.</strong>
                    </p>
                    <p>
                      Über die klassischen POS Verkaufsjobs hinaus, gibt es bei
                      uns auch viele andere Tätigkeiten rund um den POS. Für
                      Jobs wie Produktaufbauten, Regalschlichtungen und Deko
                      Touren werden immer Promoter gebraucht.
                    </p>
                  </Col>

                  <Col md={6} lg={4}>
                    <p>Wir bieten Dir POS Jobs wie z.B.:</p>
                    <ul>
                      <li>Saisonale Verkaufs Promotions</li>
                      <li>Shop in Shop Betreuung</li>
                      <li>Produktneueinführungen am POS</li>
                      <li>POS Auf-, Um- oder Abbau Jobs</li>
                      <li>Deko Touren</li>
                      <li>Regalbetreeung & Merchandising</li>
                      <li>uvm.</li>
                    </ul>

                    <p>Wir erwarten von Dir:</p>
                    <ul>
                      <li>Zuverlässigkeit und Pünktlichkeit</li>
                      <li>Gepflegtes Äußeres und exzellente Umgangsformen</li>
                      <li>Perfekte Deutschkenntnisse</li>
                      <li>Hohe Eigenverantwortung und Flexibilität</li>
                      <li>
                        Österreichische Arbeitserlaubnis und einwandfreien
                        Leumund
                      </li>
                      <li>
                        Ein Smartphone und einen Computer mit Internetzugang und
                        Ausdruckmöglichkeit
                      </li>
                    </ul>
                  </Col>

                  <Col md={12}>
                    <Button
                      className="btn"
                      variant="primary"
                      label={t('home.regLabel')}
                      type="button"
                      onClick={() => {
                        setShowEmployeeRegisterModal(true);
                        setDisableNewsletterPopUp(true);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="full-width__teaser-wrap">
              <div className="full-width__image-wrapper">
                <img src={gastrojobs} alt="placeholder-images" />
              </div>
              <div className="full-width__content">
                <Row>
                  <Col md={12}>
                    <h3>Gastro Jobs</h3>
                  </Col>

                  <Col md={6}>
                    <p>
                      Du bis gastfreundlich? Du weißt wie Top Service aussehen
                      muß? Du hast Freude mit einem Lächeln Deine Gäste zu
                      empfangen und rundum glücklich zu machen? Dann passt Du
                      perfekt in unser Superstaff Service Team. Wir freuen uns
                      auf motivierte Kolleg:innen. Es ist Deine Chance bei
                      exklusiven Events in tollen Locations mit hochrangigen
                      Gästen mitzuarbeiten.
                    </p>

                    <p>
                      <strong>
                        Elegantes Ambiente, abwechslungsreiche Einsätze, tolle
                        Kolleg:innen
                      </strong>
                    </p>
                    <p>
                      Top Hotels, ausgefallene Locations exklusive Caterings.
                      Möglichkeiten gibt es viele: Kongresse, Firmenevents,
                      Galadinners, Empfänge, Pressekonferenzen,
                      Weihnachtsfeiern, Festivals. Wenn Du willst kannst Du
                      überall dabei sein und für das leibliche Wohl der Gäste
                      sorgen. In einem tollen Team arbeitetst Du in den
                      verschiedensten Einsatzbereichen: Service, Küchenhilfe,
                      Logistiker. Dein Einsatz ist auf allen Ebenen gefragt.
                    </p>

                    <p>
                      <strong>
                        Erfahrung im Service und die Bereitschaft anzupacken
                      </strong>
                    </p>
                    <p>
                      Idealerweise verfügst Du bereits über Gastro bzw. Service
                      Erfahrung und bist motiviert im Team Glanzleistungen zu
                      vollbringen. Deine Einsatzbereitschaft, Freundlichkeit und
                      Dein kommunikatives Wesen machen Dich stets sympathisch.
                      Deine gepflegten Umgangsformen und Deine Höflichkeit sind
                      Deine Visitenkarte auch wenn es einmal stressig wird.
                    </p>
                  </Col>

                  <Col md={6} lg={4}>
                    <p>Wir bieten Dir Gastro Jobs wie z.B.: </p>
                    <ul>
                      <li>Servicedienste (Galadinner, Catering, Buffet)</li>
                      <li>Bar und Ausschank</li>
                      <li>Küchenhilfe(Vorbereitung, Speisen anrichten)</li>
                      <li>
                        Logistiker (Auf-/Abbau, Nachschub, Eindeckdienste)
                      </li>
                      <li>uvm.</li>
                    </ul>

                    <p>Wir erwarten von Dir:</p>
                    <ul>
                      <li>Zuverlässigkeit und Pünktlichkeit</li>
                      <li>Gepflegtes Äußeres und exzellente Umgangsformen</li>
                      <li>Erfahrung in der Gastronomie und Flexibilität</li>
                      <li>Perfekte Deutschkenntnisse</li>
                      <li>Freude im Umgang mit Menschen</li>
                      <li>Hohe Eigenverantwortung und Flexibilität</li>
                      <li>
                        Österreichische Arbeitserlaubnis und einwandfreien
                        Leumund
                      </li>
                      <li>
                        Ein Smartphone und einen Computer mit Internetzugang und
                        Ausdruckmöglichkeit
                      </li>
                    </ul>
                  </Col>

                  <Col md={12}>
                    <Button
                      className="btn"
                      variant="primary"
                      label={t('home.regLabel')}
                      type="button"
                      onClick={() => {
                        setShowEmployeeRegisterModal(true);
                        setDisableNewsletterPopUp(true);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="full-width__teaser-wrap">
              <div className="full-width__image-wrapper">
                <img src={hospitalityjobs} alt="placeholder-images" />
              </div>
              <div className="full-width__content">
                <Row>
                  <Col md={12}>
                    <h3>Hospitality Jobs</h3>
                  </Col>

                  <Col md={6}>
                    <p>
                      Events sind Deine Welt? Du liebst es mit vollen Einsatz
                      zum Gesamterfolg bezutragen? 100%iger Service steht für
                      Dich an erster Stelle? Dann bist Du genau richtig bei
                      unserern Hospitality Jobs. Mit Deiner Ausstrahlung
                      verzauberst Du Messegäste und bereicherst mit Deinem
                      Lächeln jedes Event.
                    </p>

                    <p>
                      <strong>
                        Einzigartiges Ambiente und abwechslungsreiche Einsätze.
                      </strong>
                    </p>
                    <p>
                      Top Hotels, VIP Lounges, ausgefallene Locations,
                      Hospitality Zelte. Einsatzmöglichkeiten gibt es viele:
                      Kongresse, Messen, VIP Bereiche bei Sportveranstaltungen
                      und Konzerten. Wenn Du willst kannst Du überall dabei sein
                      und Gäste professionell umsorgen.
                    </p>

                    <p>
                      <strong>Für die Gäste das Beste!</strong>
                    </p>
                    <p>
                      Deine Einsatzbereitschaft, Freundlichkeit und Dein
                      kommunikatives Wesen machen Dich stets sympathisch. Deine
                      außergewöhnlichen Umgangsformen, Deine Höflichkeit und
                      Dein exzellentes Äußeres sind Deine Visitenkarte.
                    </p>
                  </Col>

                  <Col md={6} lg={4}>
                    <p>Wir bieten Dir Hospitality Jobs wie z.B.:</p>
                    <ul>
                      <li>Welcome Desk Betreuung</li>
                      <li>Gästeregistrierung</li>
                      <li>Messestand Betreuung</li>
                      <li>Kongress- und Seminar Gäste Betreuung</li>
                      <li>Guest Guiding</li>
                      <li>VIP Guiding & Hosting</li>
                      <li>Event Auf- Abbau Helfer, Helping Hands</li>
                      <li>uvm.</li>
                    </ul>

                    <p>Wir erwarten von Dir:</p>
                    <ul>
                      <li>Zuverlässigkeit und Pünktlichkeit</li>
                      <li>Gepflegtes Äußeres und exzellente Umgangsformen</li>
                      <li>Erfahrung in der Gastronomie und Flexibilität</li>
                      <li>Perfekte Deutschkenntnisse</li>
                      <li>Freude im Umgang mit Menschen</li>
                      <li>Hohe Eigenverantwortung und Flexibilität</li>
                      <li>
                        Österreichische Arbeitserlaubnis und einwandfreien
                        Leumund
                      </li>
                      <li>
                        Ein Smartphone und einen Computer mit Internetzugang und
                        Ausdruckmöglichkeit
                      </li>
                    </ul>
                  </Col>

                  <Col md={12}>
                    <Button
                      className="btn"
                      variant="primary"
                      label={t('home.regLabel')}
                      type="button"
                      onClick={() => {
                        setShowEmployeeRegisterModal(true);
                        setDisableNewsletterPopUp(true);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <Row>
              <Col md={6}>
                <p>
                  <strong>Wichtige Info:</strong>
                </p>
                <p>
                  Alle Superstaff MitarbeiterInnen sind entsprechend den
                  österreichischen gesetzlichen Bestimmungen während des
                  Einsatzzeitraumes als vollversicherte Mitarbeiter angestellt.
                  Superstaff entrichtet verantwortungsvoll die geforderten
                  Dienstgeber Sozialabgaben der GKK und damit den
                  Unternehmerbeitrag zum österreichischen Sozialsystem.
                  (www.sozialversicherung.at)
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    )
  );
};

export default WorkWithUs;
